<template>
  <div class="container-fluid mv-table-container mt-2 mb-4">
    <table class="table table-bordered mv-table">
      <thead class="mv-table__head">
        <tr class="mv-table__row">
          <th scope="col">Need</th>
          <th scope="col">Urgency</th>
          <th scope="col">Type</th>
          <th scope="col">Source Types</th>
          <th scope="col">Potential Source</th>
          <th scope="col">Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="mv-table__body" id="mv-table-content" v-for="(resource, i) in resources" :key="resource">
        <NeedsFormTableRow :rowNum="i"></NeedsFormTableRow>
      </tbody>
    </table>
    <div class="d-flex justify-content-end">
      <button class="btn btn-sm btn-secondary" @click="addResource()">+ Add Row</button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import NeedsFormTableRow from '@/components/NeedsFormTableRow.vue';
import { useStore } from 'vuex';

export default {
  name: 'NeedsFormTable',
  components: {
    NeedsFormTableRow,
  },
  setup(props) {
    const store = useStore();

    return {
      resources: computed(() => store.state.resources.resourceList),
      addResource: () => store.dispatch('resources/addResource'),
    };
  },
};

</script>

<style lang="scss" scoped>
.table > :not(:first-child) {
  border-bottom: inherit;
}
.mv-table {
  border: $form-table-border;
  font-size: $form-table-font-size;
}
.mv-table thead {
  border-bottom: $form-table-border;
}
.mv-table th {
  color: $form-table-label-text-color;
}
.mv-table__row {
  td,
  .mv-table__cell {
    padding: $form-table-cell-padding;
  }
  .mv-table__input {
    background: $form-table-input-bg;
    border: none;
    color: $mv_secondary_blue;
    width: $form-table-input-width;
    &:focus {
      background: $gray-200;
      outline: none;
    }
  }
}
.mv-table__select {
  font-size: .8rem;
}
// Buttons
.btn-danger,
.btn-info,
.btn-secondary,
.btn-success {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: $light;
    transition: $transition-default;
  }
  &:focus {
    color: $light;
    transition: $transition-default;
  }
}
</style>
