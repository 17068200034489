<template>
  <div class="container-fluid mv-table-container mt-2 mb-4">
    <h2>Mosaic Village Team Roster</h2>
    <div class="row">
      <div class="col">
        <!-- eslint-disable-next-line -->
        <label class="mv-input__label" for="cityName"><strong>Mosaic Village</strong> - Your City:</label>
        <input
          v-model="cityName"
          placeholder="Your City..."
          class="form-control mv-input__input d-print-none"
          type="text"
          id="cityName"
          name="cityName">
      </div>
    </div>
    <div class="card mv-form-card my-2 p-3">
      <div class="row pb-2" v-for="(record, recordId) in roster" :key="recordId">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'rosterRole' + recordId">Role: </label>
          <select
            v-model="roster[recordId]['role']"
            @blur="editRoster(recordId, 'role', roster[recordId]['role']);"
            class="form-select form-select-sm mv-table__select"
            type="text"
            :id="'rosterRole' + recordId"
            :name="'rosterRole' + recordId">
            <option
              disabled
              selected
              value="0">
              Role...
            </option>
            <option
              v-for="(option, optionValue) in rosterRoles"
              :key="optionValue">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'rosterName' + recordId">Name: </label>
          <input
            v-model="roster[recordId]['name']"
            @blur="editRoster(recordId, 'name', roster[recordId]['name'])"
            placeholder="Name..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'rosterName' + recordId"
            :name="'rosterName' + recordId">
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'rosterJobTitle' + recordId">Job Title: </label>
          <input
            v-model="roster[recordId]['jobTitle']"
            @blur="editRoster(recordId, 'jobTitle', roster[recordId]['jobTitle'])"
            placeholder="Job Title..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'rosterJobTitle' + recordId"
            :name="'rosterJobTitle' + recordId">
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'rosterOrganization' + recordId">Organization: </label>
          <input
            v-model="roster[recordId]['organization']"
            @blur="editRoster(recordId, 'organization', roster[recordId]['organization'])"
            placeholder="Organization..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'rosterOrganization' + recordId"
            :name="'rosterOrganization' + recordId">
        </div>
        <div class="col-1 d-flex justify-content-center align-items-center">
          <button class="btn btn-outline mv-icon-btn d-print-none" aria-label="Delete Partner" @click="deleteRoster(recordId);">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button class="btn btn-sm btn-secondary d-print-none" @click="addRoster()">+ Add Row</button>
      </div>
    </div>
    <h4>Backbone Support</h4>
    <div class="card mv-form-card my-2 p-3">
      <div class="row pb-2" v-for="(record, recordId) in backboneRoster" :key="recordId">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'backboneRosterOrganization' + recordId">Organization: </label>
          <input
            v-model="backboneRoster[recordId]['organization']"
            @blur="editBackboneRoster(recordId, 'organization', backboneRoster[recordId]['organization'])"
            placeholder="Organization..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'backboneRosterOrganization' + recordId"
            :name="'backboneRosterOrganization' + recordId">
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'backboneRosterStaff' + recordId">Staff: </label>
          <input
            v-model="backboneRoster[recordId]['staff']"
            @blur="editBackboneRoster(recordId, 'staff', backboneRoster[recordId]['staff'])"
            placeholder="Staff..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'backboneRosterStaff' + recordId"
            :name="'backboneRosterStaff' + recordId">
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'backboneRosterAdminServices' + recordId">Administrative Services: </label>
          <input
            v-model="backboneRoster[recordId]['adminServices']"
            @blur="editBackboneRoster(recordId, 'adminServices', backboneRoster[recordId]['adminServices'])"
            placeholder="Administrative Services..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'backboneRosterAdminServices' + recordId"
            :name="'backboneRosterAdminServices' + recordId">
        </div>
        <div class="col-1 d-flex justify-content-center align-items-center">
          <button class="btn btn-outline mv-icon-btn d-print-none" aria-label="Delete Partner" @click="deleteBackboneRoster(recordId);">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button class="btn btn-sm btn-secondary d-print-none" @click="addBackboneRoster()">+ Add Row</button>
      </div>
    </div>
    <h4>Partners</h4>
    <div class="card mv-form-card my-2 p-3">
      <div class="row pb-2" v-for="(record, recordId) in partnersRoster" :key="recordId">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'partnerRosterSector' + recordId">Sector: </label>
          <select
            v-model="partnersRoster[recordId]['sector']"
            @blur="editPartnerRoster(recordId, 'sector', partnersRoster[recordId]['sector']);"
            class="form-select form-select-sm mv-table__select"
            type="text"
            :id="'partnerRosterSector' + recordId"
            :name="'partnerRosterSector' + recordId">
            <option
              disabled
              selected
              value="0">
              Sector...
            </option>
            <option
              v-for="(option, optionValue) in partnerSectors"
              :key="optionValue">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'partnerRosterName' + recordId">Name: </label>
          <input
            v-model="partnersRoster[recordId]['name']"
            @blur="editPartnerRoster(recordId, 'name', partnersRoster[recordId]['name'])"
            placeholder="Name..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'partnerRosterName' + recordId"
            :name="'partnerRosterName' + recordId">
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'partnerRosterTitle' + recordId">Title: </label>
          <input
            v-model="partnersRoster[recordId]['title']"
            @blur="editPartnerRoster(recordId, 'title', partnersRoster[recordId]['title'])"
            placeholder="Title..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'partnerRosterTitle' + recordId"
            :name="'partnerRosterTitle' + recordId">
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'partnerRosterOrganization' + recordId">Organization: </label>
          <input
            v-model="partnersRoster[recordId]['organization']"
            @blur="editPartnerRoster(recordId, 'organization', partnersRoster[recordId]['organization'])"
            placeholder="Organization..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'partnerRosterOrganization' + recordId"
            :name="'partnerRosterOrganization' + recordId">
        </div>
        <div class="col-1 d-flex justify-content-center align-items-center">
          <button class="btn btn-outline mv-icon-btn d-print-none" aria-label="Delete Partner" @click="deletePartnerRoster(recordId);">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button class="btn btn-sm btn-secondary d-print-none" @click="addPartnerRoster()">+ Add Row</button>
      </div>
    </div>
    <h3>Work Groups</h3>
    <div class="card mv-form-card my-2 p-3">
      <div class="row">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" for="workGroupTopic1">Work Group Topic: </label>
          <input
            v-model="topic1Name"
            placeholder="Work Group Topic..."
            class="form-control"
            type="text"
            id="workGroupTopic1"
            name="workGroupTopic1">
        </div>
      </div>
      <div class="row mt-4" v-for="(record, recordId) in workGroups[1]" :key="recordId">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'workGroup1Role' + recordId">Role: </label>
          <select
            v-model="workGroups[1][recordId]['role']"
            @blur="editRoster(recordId, 'role', workGroups[1][recordId]['role']);"
            class="form-select form-select-sm mv-table__select"
            type="text"
            :id="'workGroup1Role' + recordId"
            :name="'workGroup1Role' + recordId">
            <option
              disabled
              selected
              value="0">
              Role...
            </option>
            <option
              v-for="(option, optionValue) in workGroupRoles"
              :key="optionValue">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'workGroup1Name' + recordId">Name: </label>
          <input
            v-model="workGroups[1][recordId]['name']"
            @blur="editBackboneRoster(recordId, 'name', workGroups[1][recordId]['name'])"
            placeholder="Name..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'workGroup1Name' + recordId"
            :name="'workGroup1Name' + recordId">
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'workGroup1Organization' + recordId">Organization: </label>
          <input
            v-model="workGroups[1][recordId]['organization']"
            @blur="editBackboneRoster(recordId, 'organization', workGroups[1][recordId]['organization'])"
            placeholder="Organization..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'workGroup1Organization' + recordId"
            :name="'workGroup1Organization' + recordId">
        </div>
        <div class="col-1 d-flex justify-content-center align-items-center">
          <button class="btn btn-outline mv-icon-btn d-print-none" aria-label="Delete Partner" @click="deleteWorkGroupEntry(1, recordId);">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button class="btn btn-sm btn-secondary d-print-none" @click="addWorkGroupEntry(1)">+ Add Row</button>
      </div>
    </div>
    <div class="card mv-form-card my-2 p-3">
      <div class="row">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" for="workGroupTopic2">Work Group Topic: </label>
          <input
            v-model="topic2Name"
            placeholder="Work Group Topic..."
            class="form-control"
            type="text"
            id="workGroupTopic2"
            name="workGroupTopic2">
        </div>
      </div>
      <div class="row" v-for="(record, recordId) in workGroups[2]" :key="recordId">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'workGroup1Role' + recordId">Role: </label>
          <select
            v-model="workGroups[2][recordId]['role']"
            @blur="editRoster(recordId, 'role', workGroups[2][recordId]['role']);"
            class="form-select form-select-sm mv-table__select"
            type="text"
            :id="'workGroup1Role' + recordId"
            :name="'workGroup1Role' + recordId">
            <option
              disabled
              selected
              value="0">
              Role...
            </option>
            <option
              v-for="(option, optionValue) in workGroupRoles"
              :key="optionValue">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'workGroup1Name' + recordId">Name: </label>
          <input
            v-model="workGroups[2][recordId]['name']"
            @blur="editBackboneRoster(recordId, 'name', workGroups[2][recordId]['name'])"
            placeholder="Name..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'workGroup1Name' + recordId"
            :name="'workGroup1Name' + recordId">
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'workGroup1Organization' + recordId">Organization: </label>
          <input
            v-model="workGroups[2][recordId]['organization']"
            @blur="editBackboneRoster(recordId, 'organization', workGroups[2][recordId]['organization'])"
            placeholder="Organization..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'workGroup1Organization' + recordId"
            :name="'workGroup1Organization' + recordId">
        </div>
        <div class="col-1 d-flex justify-content-center align-items-center">
          <button class="btn btn-outline mv-icon-btn d-print-none" aria-label="Delete Partner" @click="deleteWorkGroupEntry(2, recordId);">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button class="btn btn-sm btn-secondary d-print-none" @click="addWorkGroupEntry(2)">+ Add Row</button>
      </div>
    </div>

    <div class="card mv-form-card my-2 p-3">
      <div class="row">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" for="workGroupTopic3">Work Group Topic: </label>
          <input
            v-model="topic3Name"
            placeholder="Work Group Topic..."
            class="form-control"
            type="text"
            id="workGroupTopic3"
            name="workGroupTopic3">
        </div>
      </div>

      <div class="row" v-for="(record, recordId) in workGroups[3]" :key="recordId">
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'workGroup3Role' + recordId">Role: </label>
          <select
            v-model="workGroups[3][recordId]['role']"
            @blur="editRoster(recordId, 'role', workGroups[3][recordId]['role']);"
            class="form-select form-select-sm mv-table__select"
            type="text"
            :id="'workGroup3Role' + recordId"
            :name="'workGroup3Role' + recordId">
            <option
              disabled
              selected
              value="0">
              Role...
            </option>
            <option
              v-for="(option, optionValue) in workGroupRoles"
              :key="optionValue">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'workGroup3Name' + recordId">Name: </label>
          <input
            v-model="workGroups[3][recordId]['name']"
            @blur="editBackboneRoster(recordId, 'name', workGroups[3][recordId]['name'])"
            placeholder="Name..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'workGroup3Name' + recordId"
            :name="'workGroup3Name' + recordId">
        </div>
        <div class="col">
          <!-- eslint-disable-next-line -->
          <label class="mv-input__label" :for="'workGroup3Organization' + recordId">Organization: </label>
          <input
            v-model="workGroups[3][recordId]['organization']"
            @blur="editBackboneRoster(recordId, 'organization', workGroups[3][recordId]['organization'])"
            placeholder="Organization..."
            class="form-control mv-input__input d-print-none"
            type="text"
            :id="'workGroup3Organization' + recordId"
            :name="'workGroup3Organization' + recordId">
        </div>
        <div class="col-1 d-flex justify-content-center align-items-center">
          <button class="btn btn-outline mv-icon-btn d-print-none" aria-label="Delete Partner" @click="deleteWorkGroupEntry(3, recordId);">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button class="btn btn-sm btn-secondary d-print-none" @click="addWorkGroupEntry(3)">+ Add Row</button>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'TeamRosterForm',
  setup(props) {
    const store = useStore();
    return {
      rosterRoles: computed(() => {
        return store.state.teamRoster.rosterRoles;
      }),
      partnerSectors: computed(() => {
        return store.state.teamRoster.partnerSectors;
      }),
      workGroupRoles: computed(() => {
        return store.state.teamRoster.workGroupRoles;
      }),
      roster: computed(() => {
        return store.state.teamRoster.roster;
      }),
      backboneRoster: computed(() => {
        return store.state.teamRoster.backboneRoster;
      }),
      partnersRoster: computed(() => {
        return store.state.teamRoster.partnersRoster;
      }),
      workGroups: computed(() => {
        return store.state.teamRoster.workGroups;
      }),
      addRoster: () => {
        store.dispatch('teamRoster/addRoster');
      },
      editRoster: (id, prop, value) => {
        store.dispatch('teamRoster/editRoster', { id, prop, value });
      },
      deleteRoster: (id) => {
        store.dispatch('teamRoster/deleteRoster', { id });
      },
      addBackboneRoster: () => {
        store.dispatch('teamRoster/addBackboneRoster');
      },
      editBackboneRoster: (id, prop, value) => {
        store.dispatch('teamRoster/editBackboneRoster', { id, prop, value });
      },
      deleteBackboneRoster: (id) => {
        store.dispatch('teamRoster/deleteBackboneRoster', { id });
      },
      addPartnerRoster: () => {
        store.dispatch('teamRoster/addPartnerRoster');
      },
      editPartnerRoster: (id, prop, value) => {
        store.dispatch('teamRoster/editPartnerRoster', { id, prop, value });
      },
      deletePartnerRoster: (id) => {
        store.dispatch('teamRoster/deletePartnerRoster', { id });
      },
      addWorkGroupEntry: (groupId) => {
        store.dispatch('teamRoster/addWorkGroupEntry', { groupId });
      },
      editWorkGroupEntry: (groupId, id, prop, value) => {
        store.dispatch('teamRoster/editWorkGroupEntry', { groupId, id, prop, value });
      },
      deleteWorkGroupEntry: (groupId, id) => {
        store.dispatch('teamRoster/deleteWorkGroupEntry', { groupId, id });
      },
      cityName: computed({
        get: () => store.state.teamRoster.cityName,
        set: (value) => store.dispatch('teamRoster/updateCityName', { value }),
      }),
      topic1Name: computed({
        get: () => store.state.teamRoster.topicName[1],
        set: (value) => store.dispatch('teamRoster/updateTopicName', { id: 1, value }),
      }),
      topic2Name: computed({
        get: () => store.state.teamRoster.topicName[2],
        set: (value) => store.dispatch('teamRoster/updateTopicName', { id: 2, value }),
      }),
      topic3Name: computed({
        get: () => store.state.teamRoster.topicName[3],
        set: (value) => store.dispatch('teamRoster/updateTopicName', { id: 3, value }),
      }),
    };
  },
};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 1.25rem;
  font-weight: 700;
}
h3, h4{
  font-size: 1rem;
  font-weight: 700;
}
.mv-form-card {
  background: ghostwhite;
}
.mv-icon-btn {
  padding: 1px 4px;
  i {
    font-size: 1.1rem;
  }
}
.btn-info,
.btn-secondary,
.btn-success,
.btn-danger {
&,
&:link,
&:visited,
&:active,
&:hover {
   color: $light;
 }
}
.mv-input {
  max-width: $input-group-max-width;
  width: $input-group-width;
&__label {
   color: $input-group-label-text-color;
   font-size: $input-group-label-font-size;
   font-weight: $input-group-label-font-weight;
   order:1;
 }
&__input,
&__select {
   border-radius: 3px;
   border-width: $input-group-input-border-width;
   color: $input-group-input-text-color;
   font-size: $form-control-input-font-size-base;
   order:2;
&:focus {
   border-color: $input-group-input-border-color-focus;
   box-shadow: none;
   outline: none;
 }
}
&__select {
   padding: 6px 12px;
 }
}
</style>
