<template>
  <header id="page-header">
    <h1 class="h1">Build Core Team and Secure Backbone Support</h1>
  </header>
  <div class="container py-5">
    <div class="row mb-3 justify-content-center">
      <ul class="nav nav-tabs" id="ctTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="about-tab" data-bs-toggle="tab" data-bs-target="#about" type="button" role="tab" aria-controls="about" aria-selected="true">About</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="needs-tab" data-bs-toggle="tab" data-bs-target="#needs" type="button" role="tab" aria-controls="needs" aria-selected="false">Needs and Resources Worksheet</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="team-roster-tab" data-bs-toggle="tab" data-bs-target="#team-roster" type="button" role="tab" aria-controls="team-roster" aria-selected="false">Team Roster</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="job-tab" data-bs-toggle="tab" data-bs-target="#job" type="button" role="tab" aria-controls="job" aria-selected="false">Job Description</button>
        </li>
      </ul>
      <div class="tab-content" id="caTabContent">
        <!-- About Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade show active" id="about" role="tabpanel" aria-labelledby="about-tab">
          <div class="row gx-4">
            <!-- Connect the dots -->
            <div class="col-sm-12 col-md-6">
              <h5 class="h5 fw-bold mb-4">Connect the dots</h5>
              <p class="p">
                This step builds on the connections you made in the Gather Support step and helps you shape the team you’ll need to be successful. Tools include:
              </p>
              <ul>
                <li>Needs and Resources Worksheet, to help you identify and track what you have and what you need.</li>
                <li>Team Roster, to document all participants, including leadership, staff, backbone support, partners and work groups.</li>
                <li>Community Coordinator job description to help you recruit the perfect candidate for your initiative’s most important staff role.</li>
              </ul>
            </div>
            <!-- Mosaic Village Roles -->
            <div class="col-sm-12 col-md-6">
              <h5 class="h5 fw-bold mb-4">Mosaic Village roles</h5>
              <p class="p">
                The <span class="text-primary fw-bold">core team</span> provides leadership, helping to steer your Mosaic Village initiative. Hires and provides strategic direction to the community coordinator. Sets goals in collaboration with coordinator, and builds support—financial, policy, awareness and goodwill—to help achieve those goals.
              </p>
              <p class="p">
                The <span class="text-primary fw-bold">community coordinator</span> is the hands-on leader of the effort, bringing together partners and allies to learn how they can support—and be supported by—the initiative. Serves as primary representative and spokesperson, and is accountable for the initiatives’ activities and outcomes.
              </p>
              <p class="p">
                The <span class="text-primary fw-bold">youth action council facilitator</span> recruits members and supports the youth action council, convening meetings, doing hands-on outreach, and serving as a liaison between the council and partner organizations.
              </p>
              <p class="p">
                The <span class="text-primary fw-bold">youth action council</span> ensures that youth voice is heard and included in decision-making. Members plan and execute outreach to unhoused youth, and provide feedback and advice to youth-serving organizations.
              </p>
            </div>
          </div>
        </div>
        <!-- Needs and Resources Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade" id="needs" role="tabpanel" aria-labelledby="needs-tab">
          <h5 class="h5 fw-bold mb-4 text-center">Needs and Resources Worksheet</h5>
          <NeedsFormTable numRows="4"></NeedsFormTable>
        </div>
        <!-- Team Roster Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade" id="team-roster" role="tabpanel" aria-labelledby="team-roster-tab">
            <TeamRosterForm></TeamRosterForm>
          <div class="d-flex justify-content-center">
            <a href="/docs/93-Mosaic-Village-Team-Roster.docx" class="btn btn-primary">Download Team Roster Template</a>
          </div>
        </div>
        <!-- Job Description Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade" id="job" role="tabpanel" aria-labelledby="job-tab">
          <div class="row gx-4">
            <h5 class="h5 fw-bold mb-4">Community Coordinator Job Description</h5>
            <div class="col-sm-12 col-md-6">
              <h6 class="h6 fw-bold">Title</h6>
              <p class="p">Community Coordinator</p>
              <h6 class="h6 fw-bold">Summary</h6>
              <p class="p">
                The Community Coordinator is, first and foremost, a connector. A team builder. A win-win finder. As the lead staff person for the initiative, the Community Coordinator engages partners and allies across organizations and sectors in co-creating a supportive provider network with a shared focus: connecting unaccompanied youth experiencing housing instability to the resources they need to stay in school and off the streets.
              </p>
              <h6 class="h6 fw-bold">Responsibilities</h6>
              <ul>
                <li>Implement the vision and strategic direction of the Core Team (initiative leadership), and support all work groups and task teams.</li>
                <li>Develop partnerships with providers, organizations and community leaders to build a sturdy and cohesive network; continue to grow the partnerships to meet current and emerging needs.</li>
                <li>Nurture trusting relationships with all partners and allies, and quickly address any issues that arise.</li>
                <li>Provide timely, transparent and consistent information to the Core Team, partners and funders.</li>
                <li>Educate community members and decision makers about the particular challenges and risks faced by kids navigating housing instability on their own.</li>
                <li>Build community awareness of and support for the initiative; serve as primary spokesperson.</li>
                <li>Ensure that youth are meaningfully engaged in shaping policy and developing programming.</li>
                <li>Plan events and meetings, facilitate discussions, and convene partners</li>
                <li>Develop and implement training tailored for partners, staff, and youth leaders. </li>
                <li>Track relevant policy discussions at the local, state and federal level, and advocate for the interests of unaccompanied minors.</li>
                <li>Support strategic planning processes, strategy implementation, progress monitoring, and reporting.</li>
                <li>Identify and bring in funding from diverse sources.</li>
                <li>Manage day-to-day operations, develop and track budget, and work closely with backbone support organization(s) providing administrative and technology services.</li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-6">
              <h6 class="h6 fw-bold">Skills and Experience</h6>
              <ul>
                <li>Expert negotiation, mediation, and problem-solving skills.</li>
                <li>Seasoned facilitator, accomplished at creating a safe and creative space for collaboration.</li>
                <li>Demonstrated ability to facilitate participatory decision-making processes among diverse partners, and ultimately get to “yes.”</li>
                <li>Professional experience in youth services, housing, collective impact initiatives, or other relevant disciplines.</li>
                <li>Sharp analytical, decision-making, and problem-solving skills. Able to synthesize complex and disparate information, and make sound decisions.</li>
                <li>Demonstrated experience independently developing new programs, policies and initiatives to achieve high-level strategic objectives.</li>
                <li>Strong verbal and written communication skills; able to clearly and concisely articulate complex ideas to diverse audiences.</li>
              </ul>
              <h6 class="h6 fw-bold">Personal Characteristics</h6>
              <ul>
                <li>Flexible and collaborative</li>
                <li>Sees opportunity in ambiguity</li>
                <li>Empathetic</li>
                <li>Persuasive and enthusiastic</li>
                <li>Tenacious and resilient</li>
                <li>Well-connected and respected in the community, and eager to forge new relationships</li>
                <li>Genuinely respects young people and enjoys their company</li>
                <li>Deeply committed to the right of all people to be safe, secure, and sheltered</li>
              </ul>
            </div>
            <div class="d-flex justify-content-center">
              <a href="/docs/93-Mosaic-Village-Community-Coordinator-Job-Description.docx" class="btn btn-primary">Download Job Description</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Navigation Section -->
  <div class="container py-5">
    <div class="row">
      <div class="col">
        <router-link
          tag="button"
          class="btn btn-primary float-start"
          to="/gather-support">
          <i class="bi bi-chevron-left"></i>
        </router-link>
        <router-link
          tag="button"
          class="btn btn-primary float-end"
          to="/youth-action-council">
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NeedsFormTable from '@/components/NeedsFormTable.vue';
import TeamRosterForm from '@/components/TeamRosterForm.vue';

export default {
  name: 'CoreTeamPage',
  components: {
    NeedsFormTable,
    TeamRosterForm,
  },
};
</script>

<style lang="scss" scoped>
.nav-tabs .nav-link.active {
  color: $info;
}
</style>
