<template>
  <tr class="mv-table__row">
    <!-- Need -->
    <td class="mv-table__cell">
      <!-- eslint-disable-next-line -->
      <label class="visually-hidden" :for="(cellNames[0] + rowNum)">Need</label>
      <input
        v-model="resourceList[rowNum].need"
        @blur="updateResource(rowNum, 'need', resourceList[rowNum].need);"
        placeholder="Need..."
        class="mv-table__input"
        type="text"
        :id="(cellNames[0] + rowNum)"
        :name="(cellNames[0] + rowNum)">
    </td>
    <!-- Urgency -->
    <td class="mv-table__cell">
      <select
        v-model="resourceList[rowNum].urgency"
        @blur="updateResource(rowNum, 'urgency', resourceList[rowNum].urgency);"
        onchange="this.dataset.chosen = this.value"
        :name="(cellNames[1] + rowNum)"
        :id="(cellNames[1] + rowNum)"
        class="form-select form-select-sm mv-table__select">
        <option selected disabled value="0">Urgency...</option>
        <option value="Now" class="text-danger">Now</option>
        <option value="This Year" class="text-warning">This Year</option>
        <option value="Long Term" class="text-success">Long Term</option>
      </select>
    </td>
    <!-- Type -->
    <td class="mv-table__cell">
      <select
        v-model="resourceList[rowNum].type"
        @blur="updateResource(rowNum, 'type', resourceList[rowNum].type);"
        class="form-select form-select-sm mv-table__select"
        aria-label="Select Need Type"
        :name="(cellNames[2] + rowNum)"
        :id="(cellNames[2] + rowNum)">
        <option selected disabled value="0">Type...</option>
        <option value="Administrative Support">Administrative Support</option>
        <option value="Fiscal Sponsorship">Fiscal Sponsorship</option>
        <option value="Space">Space</option>
        <option value="Equipment">Equipment</option>
        <option value="Technology Support">Technology Support</option>
        <option value="Operational Funding">Operational Funding</option>
      </select>
    </td>
    <!-- Source Type -->
    <td class="mv-table__cell">
      <select
        v-model="resourceList[rowNum].sourceType"
        @blur="updateResource(rowNum, 'sourceType', resourceList[rowNum].sourceType);"
        class="form-select form-select-sm mv-table__select "
        aria-label="Select Source Type"
        :name="(cellNames[3] + rowNum)"
        :id="(cellNames[3] + rowNum)">
        <option selected disabled value="0">Source Type...</option>
        <option value="Public Sector (local)">Public Sector (local)</option>
        <option value="Public Sector (state)">Public Sector (state)</option>
        <option value="Public Sector (federal)">Public Sector (federal)</option>
        <option value="Non-Profit">Non-Profit</option>
        <option value="Foundation">Foundation</option>
        <option value="Private Sector">Private Sector</option>
        <option value="Individual Donor">Individual Donor</option>
      </select>
    </td>
    <!-- Potential Source -->
    <td class="mv-table__cell">
      <!-- eslint-disable-next-line -->
      <label class="visually-hidden" :for="(cellNames[4] + rowNum)">Potential Source</label>
      <input
        v-model="resourceList[rowNum].potentialSource"
        @blur="updateResource(rowNum, 'potentialSource', resourceList[rowNum].potentialSource);"
        placeholder="Potential Source..."
        class="mv-table__input"
        type="text"
        :id="(cellNames[4] + rowNum)"
        :name="(cellNames[4] + rowNum)">
    </td>
    <!-- Status -->
    <td class="mv-table__cell">
      <select
        v-model="resourceList[rowNum].status"
        @blur="updateResource(rowNum, 'status', resourceList[rowNum].status);"
        :name="(cellNames[5] + rowNum)"
        :id="(cellNames[5] + rowNum)"
        class="form-select form-select-sm mv-table__select">
        <option selected disabled value="0">Status...</option>
        <option value="Now">Secured</option>
        <option value="This Year">This Year</option>
        <option value="Long Term">Long Term</option>
      </select>
    </td>
    <!-- Delete -->
    <td class="mv-table__cell d-flex align-items-center justify-content-center">
      <button class="btn btn-outline mv-icon-btn" @click="deleteResource(rowNum);">
        <i class="bi bi-trash" aria-label="Delete Resource"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'NeedsFormTableRow',
  props: {
    rowNum: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const cellNames = ['need', 'urgency', 'type', 'sourceType', 'potentialSource', 'status'];
    return {
      cellNames,
      resourceList: computed(() => store.state.resources.resourceList),
      updateResource: (id, prop, value) => store.dispatch('resources/updateResource', { id, prop, value }),
      deleteResource: (id) => {
        const c = confirm('Are you sure you want to delete this record?');
        if (c === true) {
          store.dispatch('resources/deleteResource', { id });
        }
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.mv-table__row {
  td,
  .mv-table__cell {
    padding: $form-table-cell-padding;
  }
  .mv-table__input,
  .mv-table__select {
    background: $form-table-input-bg;
    border:2px solid transparent;
    border-radius:3px;
    color: $mv_secondary_blue;
    font-size:14px;
    height:100%;
    padding:4px 2px;
    transition: $transition-default;
    width: $form-table-input-width;
    &:focus,
    &:focus-visible {
      border-color: $info;
      box-shadow:none;
      outline:none;
    }
  }
  select[data-chosen='Now'].mv-table__select,
  select[data-chosen='Now'] {
    color: $danger;
  }
  select[data-chosen='This Year'].mv-table__select,
  select[data-chosen='This Year'] {
    color: $warning;
  }
  select[data-chosen='Long Term'].mv-table__select,
  select[data-chosen='Long Term'] {
    color: $success;
  }
}
.mv-icon-btn {
  padding: 1px 4px;
  i {
    font-size: 1.1rem;
  }
}
.btn-danger {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: $light;
  }
}
</style>
